<template>
  <div
    class="resizable-wrapper-content"
    v-resizable-modal="'INSPECTION_DETAILS_VIEW_SECTION'"
    @onCloseModal="closeModal"
  >
    <div class="resizable-wrapper-content-header">
      <div class="resizable-wrapper-content-header-left">
        <h1>{{ sectionData.name }}</h1>
      </div>

      <div class="resizable-wrapper-content-header-right">
        <button
          v-if="false"
          class="btn close"
          @click="scrollToFirstUntouchedField"
        >
          view untouched
        </button>
        <div class="custom-loading" v-if="loading">
          <div class="custom-loading-icon"></div>
          <p>{{ $t("COMMON.LOADING") }}</p>
        </div>
        <button class="btn expand">
          <i class="fal fa-expand-arrows"></i>
        </button>
        <button class="btn close" @click="closeModal">
          <i class="fal fa-times"></i>
        </button>
      </div>
    </div>
    <div
      class="pl-3 pl-md-4"
      v-if="$currentUserCan($permissions.PERM_EDIT_VEHICLES)"
    >
      <vehicle-ref-standardized
        :vehicle="inspection.vehicle"
        @onEditVehicle="$emit('onEditVehicle')"
        gallery-selector
        @vehicleChanged="vehicleChanged"
      />
    </div>
    <div class="resizable-wrapper-content-body" ref="sectionModalBody">
      <div class="inspection-details">
        <div class="inspection-details-buttons">
          <base-button
            :disabled="settingLoading || loading"
            v-for="section in inspection.sectionsScores"
            :key="section.id"
            :title="section.name"
            :id="section.id"
            @click="changeSection(section)"
            v-bind:class="{ active: section.id == sectionData.id }"
          >
            <i class="fal fa-camera"></i>
            <span>
              <el-tooltip
                class="item"
                :content="section.name"
                placement="top-center"
              >
                <img
                  v-if="section.icon"
                  :src="section.icon"
                  :alt="section.name"
                />
              </el-tooltip>
            </span>
            <div class="check" v-if="section.touched && section.filled">
              <i class="fal fa-check"></i>
            </div>
            <div class="bad" v-if="section.touched && !section.filled">
              <i class="fal fa-times"></i>
            </div>
            <div class="section-score" v-if="section.touched">
              {{ section.score }}%
            </div>
          </base-button>
        </div>

        <div class="tab-pane-inner" :key="renderKey">
          <div class="inspection-details-content">
            <div class="buttons">
              <button
                @click="resetSection"
                :disabled="loading || disabled"
                :class="{ 'opacity-5 cursor-not-allowed': loading || disabled }"
              >
                {{ $t("INSPECTIONS.RESET") }}
                <i class="far fa-undo" v-if="!loading"></i>
                <i class="fas fa-spinner fa-spin" v-if="loading"></i>
              </button>
              <button
                @click="setAllGoodSection"
                :disabled="loading || disabled"
                :class="{ 'opacity-5 cursor-not-allowed': loading || disabled }"
              >
                {{ $t("INSPECTIONS.ALL_GOOD") }}
                <img src="/img/sm-check.svg" alt="icon" v-if="!loading" />
                <i class="fas fa-spinner fa-spin" v-if="loading"></i>
              </button>
            </div>
            <inspection-view-inspection-section-sub-section-view
              v-for="subSection in sectionData.subSections"
              :key="subSection.id"
              :subSectionData="subSection"
              :disabled="disabled || loading"
              :inspection="inspection"
              :settingLoading="settingLoading"
            />
          </div>
        </div>

        <div class="inspection-form-group width-1 next-button">
          <base-button
            v-if="!isFirstSection"
            size="sm"
            native-type="submit"
            :disabled="loading"
            @click="onPrevSection"
          >
            <i class="far fa-arrow-left mr-2" v-if="!loading"></i>
            <i class="fas fa-spinner fa-spin mr-2" v-if="loading"></i>
            {{ $t("INSPECTIONS.PREV_STEP") }}
          </base-button>
          <base-button
            v-if="!isLastSection"
            size="sm"
            native-type="submit"
            :disabled="loading"
            @click="onNextSection"
          >
            {{ $t("INSPECTIONS.NEXT_STEP") }}
            <i class="far fa-arrow-right" v-if="!loading"></i>
            <i class="fas fa-spinner fa-spin" v-if="loading"></i>
          </base-button>
          <base-button
            size="sm"
            native-type="submit"
            :disabled="!isFormFilled || !allSectionsTouched || loading"
            @click="onEndForm"
          >
            {{ $t("INSPECTIONS.END_FORM") }}
            <i class="far fa-arrow-right" v-if="!loading"></i>
            <i class="fas fa-spinner fa-spin" v-if="loading"></i>
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { Button, Tooltip } from "element-ui";
import { INSPECTION_FORM_FIELD_TYPE_CHECK } from "@/constants/inspectionForms";
import InspectionViewInspectionSectionSubSectionView from "./InspectionViewInspectionSectionSubSectionView.vue";
import {
  canShowElement,
  isSectionTouched,
  isSectionFillComplete,
  getUntouchedFields,
} from "@/helpers/formFieldHelper";
import VehicleRefStandardized from "@/components/VehicleRefStandardized.vue";

export default {
  name: "inspection-view-inspection-section-modal",

  components: {
    [Button.name]: Button,
    [Tooltip.name]: Tooltip,
    InspectionViewInspectionSectionSubSectionView,
    VehicleRefStandardized,
  },

  props: [
    "sectionData",
    "inspection",
    "isLastSection",
    "isFirstSection",
    "disabled",
    "loading",
  ],

  data() {
    return {
      canShowElement: canShowElement,
      settingLoading: false,
      renderKey: 0,
      isSectionTouched: isSectionTouched,
      isSectionFillComplete: isSectionFillComplete,
      isPageTouched: false,
      firstScroll: true,
    };
  },

  computed: {
    allSectionsTouched() {
      if (this.isLastSection) {
        return Object.values(this.inspection.sectionsScores)
          .filter((section) => section.id != this.sectionData.id)
          .every((section) => section.touched);
      }
      return Object.values(this.inspection.sectionsScores).every(
        (section) => section.touched
      );
    },
    isFormFilled() {
      const untouchedFields = getUntouchedFields(this.inspection);
      return untouchedFields.length == 0;
    },
  },

  created() {},

  mounted() {
    setTimeout(
      function () {
        this.scrollToFirstUntouchedField();
      }.bind(this),
      200
    );
  },

  methods: {
    getSectionScore(section) {
      return this.inspection.sectionsScores[section.id].score;
    },

    closeModal() {
      this.$emit("onCloseModal");
    },

    changeSection(section) {
      this.$emit("onChangeSection", section);
      this.$refs.sectionModalBody.scrollTop = 0;
      this.firstScroll = true;
    },

    async onNextSection() {
      this.$emit("onNextSection");
      this.$refs.sectionModalBody.scrollTop = 0;
      this.firstScroll = true;
    },

    async onPrevSection() {
      this.$emit("onPrevSection");
      this.$refs.sectionModalBody.scrollTop = 0;
      this.firstScroll = true;
    },

    async onEndForm() {
      this.$emit("onEndForm");
      this.$refs.sectionModalBody.scrollTop = 0;
      this.firstScroll = true;
    },

    async resetSection() {
      this.settingLoading = true;
      swal.fire({
        title: this.$t("INSPECTIONS.RESET"),
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      await this.$store.dispatch("inspections/resetSection", {
        inspectionId: this.inspection.id,
        data: {
          sections: [
            { type: "inspection-form-sections", id: this.sectionData.id },
          ],
        },
      });

      this.renderKey++;
      await new Promise((resolve) => setTimeout(resolve, 1000));

      this.settingLoading = false;
      swal.close();
    },

    async setAllGoodSection() {
      this.settingLoading = true;
      swal.fire({
        title: this.$t("INSPECTIONS.ALL_GOOD"),
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      await this.$store.dispatch("inspections/setSectionGood", {
        inspectionId: this.inspection.id,
        data: {
          sections: [
            { type: "inspection-form-sections", id: this.sectionData.id },
          ],
        },
      });

      this.renderKey++;
      await new Promise((resolve) => setTimeout(resolve, 1000));

      this.settingLoading = false;
      swal.close();
    },

    scrollToFirstUntouchedField() {
      if (!this.sectionData.touched) {
        return;
      }
      for (const subSection of Object.values(this.sectionData.subSections)) {
        for (const field of Object.values(subSection.fields)) {
          if (field.field_type != INSPECTION_FORM_FIELD_TYPE_CHECK) {
            if (!field.touched) {
              if (this.firstScroll && !this.settingLoading) {
                const targetElement = document.querySelector(
                  `#field_${field.id}`
                );
                const container = document.querySelector(
                  `.tab-pane.active .resizable-wrapper-content-body`
                );

                if (targetElement && container) {
                  // Get the element's position relative to the container
                  const elementRect = targetElement.getBoundingClientRect();
                  const containerRect = container.getBoundingClientRect();
                  const scrollToPosition =
                    elementRect.top -
                    containerRect.top +
                    container.scrollTop -
                    50;

                  // Smooth scroll to the element
                  container.scrollTo({
                    top: scrollToPosition,
                    behavior: "smooth",
                  });

                  // Blink the element
                  let blinkCount = 0;
                  const blinkInterval = setInterval(() => {
                    targetElement.style.visibility =
                      blinkCount % 2 === 0 ? "hidden" : "visible";
                    blinkCount++;
                    if (blinkCount >= 4) {
                      clearInterval(blinkInterval);
                      targetElement.style.visibility = "visible"; // Ensure visibility is restored
                    }
                  }, 200);
                }
                this.firstScroll = false;
                return;
              }
            }
          }
        }
      }
    },

    vehicleChanged(vehicle) {
      this.$store.commit("inspections/SET_RESOURCE", {
        ...this.inspection,
        vehicle,
      });

      this.$emit("vehicleChanged");
    },
  },

  watch: {
    sectionData(sectionData) {
      setTimeout(
        function () {
          this.scrollToFirstUntouchedField();
        }.bind(this),
        200
      );
    },
  },
};
</script>
