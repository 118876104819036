<template>
  <view-object-ref-widget
    :image="image"
    :second-title="vehicle.vin"
    :title="$objectDenomination(vehicle)"
    class="border-0"
    :object="vehicle"
    @imageAdded="updateVehicle"
  >
    <template #second-title>
      <ul>
        <li>{{ vehicle.odometer }} {{ vehicle.odometer_unit }}</li>
        <li class="line">|</li>
        <li><copy-element :element="vehicle.vin" /></li>
      </ul>
      <slot name="second-title"> </slot>
    </template>
    <template #buttons>
      <slot name="buttons">
        <button
          class="edit"
          @click="update"
          v-if="$currentUserCan($permissions.PERM_EDIT_VEHICLES)"
        >
          <span> {{ $t("COMMON.UPDATE") }} </span>
          <i class="far fa-edit"></i>
        </button>
        <span v-else></span>
      </slot>
    </template>
  </view-object-ref-widget>
</template>

<script>
import CopyElement from "./CopyElement.vue";
import ViewObjectRefWidget from "./ViewObjectRefWidget.vue";
import { QUERY_ACTIONS_EDIT } from "@/constants/common";

export default {
  name: "vehicle-ref-standardized",

  components: { CopyElement, ViewObjectRefWidget },

  props: {
    vehicle: {
      type: Object,
      required: true,
    },
    gallerySelector: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const vehicleData = this.vehicle;
    const gallery = this.vehicle.gallery;
    return {
      QUERY_ACTIONS_EDIT,
      vehicleData,
      gallery,
    };
  },

  computed: {
    image() {
      return this.gallerySelector &&
        this.$currentUserCan(this.$permissions.PERM_EDIT_VEHICLES)
        ? this.gallery
        : this.gallery[0] ?? null;
    },
  },

  methods: {
    update() {
      this.$emit("onEditVehicle");
      if (this.$route.name != "List Vehicles") {
        this.$router.push({
          name: "List Vehicles",
          query: { id: this.vehicle.id, action: QUERY_ACTIONS_EDIT },
        });
      }
    },

    async updateVehicle(gallery) {
      try {
        await this.$store.dispatch("vehicles/update", {
          id: this.vehicle.id,
          type: this.vehicle.type,
          gallery,
        });

        const vehicle = this.$store.getters["vehicles/vehicle"];

        this.$emit("vehicleChanged", vehicle);

        this.gallery = vehicle.gallery;
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("VEHICLES.VEHICLE_UPDATED"),
        });
      } catch (error) {}
    },
  },

  watch: {
    vehicle: function () {
      this.gallery = this.vehicle.gallery;
    },
  },
};
</script>

<style lang="scss" scoped></style>
