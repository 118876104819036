<template>
  <div class="elite-tabs-wrapper-content">
    <inspection-ref-standardized
      :inspection="inspection"
      :title="title"
      @vehicleChanged="$emit('onViewInspection')"
    >
      <template #buttons>
        <button class="edit" @click="showEstimation" v-if="canShowEstimation">
          <span> {{ $t("INSPECTIONS.SHOW_ESTIMATION") }} </span>
          <i class="fa-light fa-eye"></i>
        </button>
      </template>
    </inspection-ref-standardized>

    <div class="global-body">
      <estimate-view-global
        :estimate="inspection.estimation"
        :view-header="false"
        show-code
      />
    </div>
  </div>
</template>

<script>
import InspectionRefStandardized from "@/components/InspectionRefStandardized.vue";
import EstimateViewGlobal from "../../EstimateManagement/partials/EstimateViewGlobal.vue";

export default {
  name: "inspection-view-estimate",

  components: { EstimateViewGlobal, InspectionRefStandardized },

  props: ["inspection"],

  data() {
    return {};
  },

  computed: {
    canShowEstimation: function () {
      return (
        !!this.inspection.estimation &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_MODULE_ESTIMATIONS) &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_ESTIMATIONS)
      );
    },
    title: function () {
      return `${this.inspection.code} - ${this.$t("COMMON.ESTIMATION")}`;
    },
  },

  created() {},

  methods: {
    showEstimation() {
      this.$router.push(this.$objectViewRoute(this.inspection.estimation));
    },
  },

  mounted() {},

  watch: {},
};
</script>
