<template>
  <div class="global-header">
    <button
      v-if="gallerySelector"
      class="global-header-image border-0"
      @click="showPictureModal = true"
    >
      <img v-if="mainImage" :src="mainImage" class="object-cover" alt="logo" />
      <img v-else class="default" src="/img/add-image.svg" />
    </button>
    <div v-else class="global-header-image">
      <img v-if="mainImage" :src="mainImage" class="object-cover" alt="logo" />
      <img v-else class="default" src="/img/add-image.svg" />
    </div>
    <div class="global-header-text">
      <h3>{{ title }}</h3>
      <slot name="second-title">
        <h4 class="text-muted" v-if="secondTitle">{{ secondTitle }}</h4>
      </slot>
      <div v-if="showBtn" class="buttons">
        <slot name="buttons">
          <base-button
            class="btn edit"
            size="sm"
            type="button"
            @click.prevent="btnAction"
          >
            <span>{{ btnLabel ?? $t("COMMON.UPDATE") }}</span>
            <i class="fal fa-edit"></i>
          </base-button>
        </slot>
      </div>
    </div>

    <modal
      :showClose="true"
      :show.sync="showPictureModal"
      modal-classes="picture"
      v-if="gallerySelector"
    >
      <template slot="header">
        <h5 class="modal-title" id="inspection-picture">
          {{ $t("INSPECTIONS.ADD_PICTURES") }}
        </h5>
      </template>
      <div>
        <gallery-selector
          :label="$t('COMMON.PICTURES')"
          :defaultGallery="imageModel"
          :ressource_name="object.type"
          :ressource_id="object.id ? object.id : 0"
          :field="object.name"
          @galleryChanged="
            (gallery_urls) => {
              imageModel = gallery_urls;
            }
          "
        />
      </div>
      <template slot="footer">
        <!-- <base-button
          type="secondary"
          class="btn close"
          @click="showPictureModal = false"
        >
          {{ $t("COMMON.CLOSE") }}
        </base-button> -->
        <base-button type="primary" class="btn save" @click="addImages">
          {{ $t("COMMON.SAVE") }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import GallerySelector from "@/components/GallerySelector.vue";
import { cloneDeep } from "lodash";

export default {
  name: "view-object-ref-widget",

  components: { GallerySelector },

  props: {
    image: {
      type: Array | String | null | undefined,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    secondTitle: {
      type: String,
      required: null,
    },
    showBtn: {
      type: Boolean,
      default: true,
    },
    btnLabel: {
      type: String | null | undefined,
      default: null,
    },
    btnAction: {
      type: Function,
      default: () => {},
    },
    object: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    const gallery = cloneDeep(this.image);
    return {
      gallery,
      imageModel: cloneDeep(gallery),
      showPictureModal: false,
    };
  },

  computed: {
    gallerySelector() {
      return Array.isArray(this.gallery);
    },

    mainImage() {
      return this.gallerySelector ? this.image[0] ?? null : this.image;
    },
  },

  methods: {
    addImages() {
      this.gallery = cloneDeep(this.imageModel);
      this.$nextTick(function () {
        this.showPictureModal = false;
        this.$emit("imageAdded", this.gallery);
      });
    },
  },

  watch: {
    image() {
      this.gallery = cloneDeep(this.image);
    },

    showPictureModal() {
      if (!this.showPictureModal) {
        this.imageModel = cloneDeep(this.gallery);
      }
    },

    gallery() {
      this.imageModel = cloneDeep(this.gallery);
    },
  },
};
</script>
