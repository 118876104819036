<template>
  <div class="elite-tabs-wrapper-content">
    <vehicle-ref-standardized
      :vehicle="vehicle"
      @onEditVehicle="$emit('onEditVehicle')"
      @vehicleChanged="$emit('onViewVehicle')"
      gallery-selector
    />
    <!-- <slot name="header">
      <h3>{{ $objectDenomination(vehicle) }}</h3>
    </slot> -->
    <div class="resume-list">
      <div class="resume-list-title">
        <h2>{{ $t("VEHICLES.VEHICLE_IDENTIFICATION") }}</h2>
      </div>
      <div class="resume-list-inner">
        <dl v-if="false">
          <dt>{{ $t("COMMON.LOCATION") }}</dt>
          <dd>
            <locations :locations="vehicle.allowedLocations" />
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("VEHICLES.SKU_NUMBER") }}</dt>
          <dd>
            <copy-element v-if="vehicle.sku" :element="vehicle.sku" />
            <span else>-</span>
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("VEHICLES.VIN") }}</dt>
          <dd>
            <copy-element :element="vehicle.vin" />
          </dd>
        </dl>

        <dl class="width_1-4">
          <dt>{{ $t("VEHICLES.MODEL_YEAR") }}</dt>
          <dd>
            {{ vehicle.model_year }}
          </dd>
        </dl>

        <dl class="width_1-4">
          <dt>{{ $t("VEHICLES.MAKE") }}</dt>
          <dd>
            {{ vehicle.make }}
          </dd>
        </dl>

        <dl class="width_1-4">
          <dt>{{ $t("VEHICLES.MODEL") }}</dt>
          <dd>
            {{ vehicle.model ?? "-" }}
          </dd>
        </dl>

        <dl class="width_1-4">
          <dt>{{ $t("VEHICLES.SERIES") }}</dt>
          <dd>
            {{ vehicle.series ?? "-" }}
          </dd>
        </dl>

        <dl class="width_1-4">
          <dt>{{ $t("VEHICLES.STYLE") }}</dt>
          <dd>
            {{ vehicle.style ?? "-" }}
          </dd>
        </dl>

        <dl class="width_1-4">
          <dt>{{ $t("VEHICLES.ENGINE_DESCRIPTION") }}</dt>
          <dd>
            {{ vehicle.engine_description ?? "-" }}
          </dd>
        </dl>

        <dl class="width_1-4">
          <dt>{{ $t("VEHICLES.FUEL_TYPE") }}</dt>
          <dd>
            {{
              vehicle.fuel_type
                ? $t(`FUEL_TYPES.${vehicle.fuel_type.toUpperCase()}`)
                : "-"
            }}
          </dd>
        </dl>

        <dl class="width_1-4">
          <dt>{{ $t("VEHICLES.CLASS_NAME") }}</dt>
          <dd>
            {{
              $te(`VEHICLES_CLASSES.VEHICLE_${vehicle.class_code}`)
                ? $t(`VEHICLES_CLASSES.VEHICLE_${vehicle.class_code}`)
                : vehicle.class_code
            }}
          </dd>
        </dl>

        <dl class="width_1-4">
          <dt>{{ $t("VEHICLES.REGISTRATION_NUMBER") }}</dt>
          <dd>
            {{ vehicle.registration_number ?? "-" }}
          </dd>
        </dl>

        <dl class="width_1-4">
          <dt>{{ $t("VEHICLES.COLOR") }}</dt>
          <dd>
            {{ vehicle.color ?? "-" }}
          </dd>
        </dl>

        <dl class="width_1-4" v-if="!isNaN(vehicle.odometer)">
          <dt>{{ $t("VEHICLES.ODOMETER") }}</dt>
          <dd>{{ vehicle.odometer }} {{ vehicle.odometer_unit }}</dd>
        </dl>

        <dl class="width_1-4" v-if="false">
          <dt>{{ $t("VEHICLES.TRANSMISSION") }}</dt>
          <dd>
            {{
              $te(`TRANSMISSIONS.${vehicle.transmission?.toUpperCase()}`)
                ? $t(`TRANSMISSIONS.${vehicle.transmission?.toUpperCase()}`)
                : vehicle.transmission
            }}
          </dd>
        </dl>

        <dl class="width_1-4" v-if="false">
          <dt>{{ $t("VEHICLES.DRIVETRAIN") }}</dt>
          <dd>
            {{
              $te(`DRIVETRAIN.DRIVETRAIN_${vehicle.drivetrain}`)
                ? $t(`DRIVETRAIN.DRIVETRAIN_${vehicle.drivetrain}`)
                : vehicle.drivetrain
            }}
          </dd>
        </dl>

        <dl class="width_1-4" v-if="false && vehicle.customer">
          <dt>{{ $t("COMMON.CUSTOMER") }}</dt>
          <dd>
            <object-link :object="vehicle.customer" />
          </dd>
        </dl>

        <dl class="widt_1-4" v-if="false && vehicle.mecanic">
          <dt>{{ $t("COMMON.MECANIC") }}</dt>
          <dd>
            <object-link :object="vehicle.mecanic" />
          </dd>
        </dl>

        <dl
          class="width_1-4"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
            !!vehicle.organization
          "
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <object-link :object="vehicle.organization" />
          </dd>
        </dl>

        <dl class="width_1-4">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="vehicle.created_at">
            {{ $timeZoneDateFormat(vehicle.created_at) }}
          </dd>
        </dl>

        <dl class="width_1-4">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="vehicle.updated_at">
            {{ $timeZoneDateFormat(vehicle.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import CopyElement from "@/components/CopyElement.vue";
import VehicleRefStandardized from "@/components/VehicleRefStandardized.vue";

export default {
  name: "vehicle-view-global",

  components: { CopyElement, VehicleRefStandardized },

  props: ["vehicle"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    vehicle(vehicle) {},
  },
};
</script>
