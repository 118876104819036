<template>
  <div class="list-table-inner">
    <div class="list-table-inner-header" v-if="addSection">
      <div class="search">
        <div class="search-add">
          <base-button
            class="add"
            icon
            size="sm"
            @click="addInspection"
            v-if="$currentUserCan($permissions.PERM_CREATE_INSPECTIONS)"
          >
            <span class="btn-inner--icon">
              <i class="fa-regular fa-circle-plus"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("INSPECTIONS.ADD_INSPECTION") }}
            </span>
          </base-button>
        </div>
        <div class="search-form">
          <base-input
            v-model="query"
            type="search"
            prepend-icon="fal fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
          <button
            @click="
              () => {
                showAdvancedFilter = !showAdvancedFilter;
              }
            "
          >
            <i class="far fa-sliders-h"></i>
          </button>
        </div>
        <div>
          <base-button
            class="notification"
            type="info"
            icon
            size="sm"
            :title="$t('COMMON.SHOW_DELETED_ITEMS')"
            :class="{ active: onlyTrashed }"
            @click="onlyTrashed = !onlyTrashed"
          >
            <span class="btn-inner--icon">
              <i class="fa-light fa-trash"></i>
            </span>
          </base-button>
        </div>

        <!--<notification-subscription
          v-if="$currentUserCan($permissions.PERM_VIEW_INSPECTIONS)"
          :objectType="'inspections'"
          :events="{
            CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
            UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
            DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
          }"
        />-->
      </div>
      <transition name="slide">
        <div class="advanced-search" v-if="showAdvancedFilter">
          <div class="advanced-search-item pagination-select">
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              :placeholder="$t('COMMON.PER_PAGE')"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>

          <div
            v-if="
              !filterOrganization &&
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            "
            class="advanced-search-item"
          >
            <organization-selector
              @organizationChanged="
                (organizationId) => (selectedOrganization = organizationId)
              "
            />
          </div>
          <div
            v-if="
              $currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS) && false
            "
            class="advanced-search-item"
          >
            <base-input :placeholder="`${$t('COMMON.LOCATIONS')}`">
              <locations-selector
                @locationsChanged="
                  (locations) => (selectedLocations = locations)
                "
              />
            </base-input>
          </div>
          <div class="advanced-search-item">
            <el-select class="select-primary" v-model="selectedStatus">
              <el-option
                class="select-primary"
                key="0"
                :label="$t('COMMON.STATUS') + '...'"
                :value="null"
              />
              <el-option
                v-for="value in inspectionStatusesOption"
                class="select-primary"
                :key="value"
                :label="$t(`INSPECTIONS.INSPECTION_STATUS_${value}`)"
                :value="value"
              />
            </el-select>
          </div>
          <div class="advanced-search-item date">
            <date-range-selector
              :value="selectedDateRange"
              @onDateRangeChanged="
                (dates) => {
                  selectedDateRange = dates;
                }
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterAssignedTo &&
              $currentUserCan($permissions.PERM_VIEW_ANY_USERS)
            "
          >
            <user-selector
              :placeholder="$t('REQUESTS.ASSIGNED_TO')"
              @userChanged="(userId) => (selectedAssignedTo = userId)"
              :filter-permission="$permissions.PERM_START_INSPECTIONS"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS) && false"
          >
            <user-selector
              :placeholder="$t('REQUESTS.ASSIGNED_BY')"
              @userChanged="(userId) => (selectedAssignedBy = userId)"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ROLES) && false"
          >
            <role-selector
              :placeholder="$t('REQUESTS.ASSIGNED_TO_GROUP')"
              @roleChanged="(roleId) => (selectedAssignedToGroup = roleId)"
              :filter-permission="$permissions.PERM_START_INSPECTIONS"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterCreator &&
              $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
              false
            "
          >
            <user-selector
              :placeholder="$t('REQUESTS.CREATOR')"
              @userChanged="(userId) => (selectedCreator = userId)"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
          >
            <user-selector
              :placeholder="$t('REQUESTS.COMPLETED_BY')"
              @userChanged="(userId) => (selectedCompletedBy = userId)"
              :filter-permission="$permissions.PERM_START_INSPECTIONS"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS) && false"
          >
            <user-selector
              :placeholder="$t('REQUESTS.CANCELED_BY')"
              @userChanged="(userId) => (selectedCanceledBy = userId)"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterInspectionForm &&
              $currentUserCan($permissions.PERM_VIEW_ANY_INSPECTION_FORMS)
            "
          >
            <inspection-form-selector
              @inspectionFormChanged="
                (inspectionFormId) =>
                  (selectedInspectionForm = inspectionFormId)
              "
              :ignore-location-filter="true"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterCustomer &&
              $currentUserCan($permissions.PERM_VIEW_ANY_CUSTOMERS) &&
              false
            "
          >
            <customer-selector
              :disabled="false"
              @customerChanged="(customerId) => (selectedCustomer = customerId)"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterVehicle &&
              $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES)
            "
          >
            <years-selector
              :filterable="true"
              :value="selectedVehicleModelYear"
              allow-none
              @yearsChanged="
                (year) => {
                  selectedVehicleModelYear = year;
                }
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterVehicle &&
              $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES)
            "
          >
            <make-selector
              allow-none
              :year="selectedVehicleModelYear"
              :filterable="true"
              :placeholder="$t('VEHICLES.MAKE')"
              :value="selectedVehicleMake"
              @makeChanged="
                (make) => {
                  selectedVehicleMake = make;
                }
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterVehicle &&
              $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES)
            "
          >
            <model-selector
              allow-none
              :year="selectedVehicleModelYear"
              :filterable="true"
              :make="selectedVehicleMake"
              :value="selectedVehicleModel"
              @modelChanged="
                (model) => {
                  selectedVehicleModel = model;
                }
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterVehicle &&
              $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES) &&
              false
            "
          >
            <serie-selector
              allow-none
              :year="selectedVehicleModelYear"
              :model="selectedVehicleModel"
              :filterable="true"
              :value="selectedVehicleSeries"
              @serieChanged="
                (serie) => {
                  selectedVehicleSeries = serie;
                }
              "
            />
          </div>
          <div class="advanced-search-item" v-if="false">
            <tags-selector
              @tagsChanged="
                (tags) => {
                  selectedTags = tags;
                }
              "
              :organization="selectedOrganization"
            />
          </div>
          <div class="advanced-search-item d-inline-flex">
            <base-input
              :placeholder="$t('COMMON.ODOMETER_FROM')"
              v-model.number="odometers.from"
              type="number"
            />
            <base-input
              :placeholder="$t('COMMON.ODOMETER_TO')"
              v-model.number="odometers.to"
              type="number"
            />
            <!-- <el-select
              v-model="odometer_unit"
              :placeholder="$t('VEHICLES.ODOMETER_UNIT')"
            >
              <el-option value="km" :label="$t('ODOMETERS.KM')" />
              <el-option value="miles" :label="$t('ODOMETERS.MILES')" />
            </el-select> -->
          </div>
          <div class="advanced-search-item update">
            <base-button class="add" icon size="sm" @click="resetFilter">
              <span class="btn-inner--icon">
                <i class="far fa-sync-alt"></i>
              </span>
              <span class="btn-inner--text"> {{ $t("COMMON.RESET") }} </span>
            </base-button>
          </div>
        </div>
      </transition>
    </div>

    <inspection-table
      v-if="showInProgresses"
      :columns="columns"
      :filter-status="INSPECTION_STATUS_IN_PROGRESS"
      :filterVehicle="filterVehicle"
      :header="$t('COMMON.IN_PROGRESS')"
      :query="query"
      :selectedAssignedBy="selectedAssignedBy"
      :selectedAssignedTo="selectedAssignedTo"
      :selectedAssignedToGroup="selectedAssignedToGroup"
      :selectedCreator="selectedCreator"
      :selectedCustomer="selectedCustomer"
      :selectedDateRange="selectedDateRange"
      :selectedInspectionForm="selectedInspectionForm"
      :selectedOrganization="selectedOrganization"
      :selectedVehicleMake="selectedVehicleMake"
      :selectedVehicleModel="selectedVehicleModel"
      :selectedVehicleModelYear="selectedVehicleModelYear"
      :selectedVehicleSeries="selectedVehicleSeries"
      :show-pagination="false"
      :filter-odometer="odometers"
      :only-trashed="onlyTrashed"
      @onShareInspection="shareInspection"
      @onViewInspection="viewInspection"
      ref="inProgressTable"
    />

    <inspection-table
      :columns="columns"
      :exclude-status="showAll ? null : INSPECTION_STATUS_IN_PROGRESS"
      :filterVehicle="filterVehicle"
      :per-page="pagination.perPage"
      :query="query"
      :filter-status="showAll ? [] : selectedStatus"
      :selectedAssignedBy="selectedAssignedBy"
      :selectedAssignedTo="selectedAssignedTo"
      :selectedAssignedToGroup="selectedAssignedToGroup"
      :selectedCanceledBy="selectedCanceledBy"
      :selectedCompletedBy="selectedCompletedBy"
      :selectedCreator="selectedCreator"
      :selectedCustomer="selectedCustomer"
      :selectedDateRange="selectedDateRange"
      :selectedInspectionForm="selectedInspectionForm"
      :selectedOrganization="selectedOrganization"
      :selectedVehicleMake="selectedVehicleMake"
      :selectedVehicleModel="selectedVehicleModel"
      :selectedVehicleModelYear="selectedVehicleModelYear"
      :selectedVehicleSeries="selectedVehicleSeries"
      :show-pagination="showPagination"
      :show-tabs="showTabs"
      :filter-odometer="odometers"
      :only-trashed="onlyTrashed"
      @onShareInspection="shareInspection"
      @onViewInspection="viewInspection"
      ref="inspectionTable"
    />
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  inspectionStatusesOption,
  INSPECTION_STATUS_DRAFT,
  INSPECTION_STATUS_PENDING,
  INSPECTION_STATUS_ASSIGNED,
  INSPECTION_STATUS_CANCELED,
  INSPECTION_STATUS_COMPLETED,
} from "@/constants/inspections";
//import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import InspectionFormSelector from "@/components/InspectionFormSelector.vue";
import DateRangeSelector from "@/components/DateRangeSelector.vue";
import CustomerSelector from "@/components/CustomerSelector.vue";
// import Tags from "@/components/Tags.vue";
import UserSelector from "@/components/UserSelector.vue";
import TagsSelector from "@/components/TagsSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
// import NotificationSubscription from "@/components/NotificationSubscription.vue";
//import InspectionStatusBadge from "./InspectionStatusBadge.vue";
import RoleSelector from "@/components/RoleSelector.vue";
import YearSelector from "@/components/YearSelector.vue";
import YearsSelector from "@/components/YearsSelector.vue";
import MakeSelector from "@/components/MakeSelector.vue";
import ModelSelector from "@/components/ModelSelector.vue";
import SerieSelector from "@/components/SerieSelector.vue";
import Users from "@/components/Users.vue";
import CopyElement from "@/components/CopyElement.vue";
import InspectionTable from "./InspectionTable.vue";
import { INSPECTION_STATUS_IN_PROGRESS } from "@/constants/inspections";

export default {
  name: "inspection-list-table",

  components: {
    //BasePagination,
    OrganizationSelector,
    InspectionFormSelector,
    CustomerSelector,
    // Tags,
    TagsSelector,
    LocationsSelector,
    //InspectionStatusBadge,
    // NotificationSubscription,
    DateRangeSelector,
    UserSelector,
    RoleSelector,
    YearSelector,
    YearsSelector,
    MakeSelector,
    ModelSelector,
    SerieSelector,
    Users,
    CopyElement,
    InspectionTable,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "organization id",
    },
    filterInspectionForm: {
      type: String,
      default: null,
      description: "inspection form id",
    },
    filterCustomer: {
      type: String,
      default: null,
      description: "customer id",
    },
    filterStatus: {
      type: String,
      default: null,
      description: "Status",
    },
    filterAssignedTo: {
      type: String,
      default: null,
      description: "Assigned To",
    },
    filterAssignedToGroup: {
      type: Boolean,
      default: false,
      description: "Assigned To Group",
    },
    filterCreator: {
      type: String,
      default: null,
      description: "Creator",
    },
    filterVehicle: {
      type: String,
      default: null,
      description: "Creator",
    },
    showInProgresses: {
      type: Boolean,
      default: false,
    },
    showTabs: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array | null,
      default: () => [
        "vehicle",
        "vin",
        "customer",
        "model",
        "status",
        "mechanic",
        // "picture_count",
        "official_mechanic",
        "updated_at",
      ],
    },
    addSection: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-updated_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      INSPECTION_STATUS_IN_PROGRESS,
      inspectionStatusesOption,
      inspections: [],
      loading: true,
      selectedOrganization: null,
      selectedDateRange: null,
      selectedInspectionForm: null,
      selectedCustomer: null,
      selectedLocations: null,
      selectedTags: [],
      selectedStatus: null,
      showAdvancedFilter: false,
      selectedAssignedTo: null,
      selectedCreator: null,
      selectedAssignedBy: null,
      selectedAssignedToGroup: null,
      selectedCanceledBy: null,
      selectedCompletedBy: null,
      selectedVehicleModelYear: null,
      selectedVehicleMake: null,
      selectedVehicleModel: null,
      selectedVehicleSeries: null,
      onlyTrashed: false,
      odometers: { from: null, to: null },
      odometer_unit: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    params(status, creator = null) {
      let params = {
        filter: {},
        page: {
          number: 1,
          size: 1,
        },
        include: "",
      };

      if (status) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            status: status,
          },
        };
      }
      if (creator) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            creator: creator,
          },
        };
      }

      return params;
    },
  },

  methods: {
    shareInspection(inspection) {
      this.$emit("onShareInspection", inspection);
    },

    viewInspection(inspection) {
      this.$emit("onViewInspection", inspection);
    },

    editInspection(inspection) {
      if (!this.canEditInspection(inspection)) {
        return;
      }
      this.$emit("onEditInspection", inspection);
    },

    addInspection() {
      this.$emit("onAddInspection");
    },

    canEditInspection(inspection) {
      return (
        !!inspection &&
        ![INSPECTION_STATUS_CANCELED, INSPECTION_STATUS_COMPLETED].includes(
          inspection.status
        ) &&
        (this.$currentUserCan(this.$permissions.PERM_EDIT_INSPECTIONS) ||
          this.$currentUserCan(this.$permissions.PERM_APPROVE_INSPECTIONS) ||
          this.$currentUserCan(this.$permissions.PERM_ASSIGN_INSPECTIONS))
      );
    },

    canDeleteInspection(inspection) {
      return (
        !!inspection &&
        inspection.status == INSPECTION_STATUS_DRAFT &&
        this.$currentUserCan(this.$permissions.PERM_DELETE_INSPECTIONS)
      );
    },

    canShareInspection(inspection) {
      return (
        !!inspection &&
        !!inspection.share_key &&
        inspection.status == INSPECTION_STATUS_COMPLETED
      );
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },

    canGenerateReport(inspection) {
      return (
        this.$currentUserCan(this.$permissions.PERM_VIEW_INSPECTIONS) &&
        !!inspection &&
        inspection.status == INSPECTION_STATUS_COMPLETED
      );
    },

    refreshTable() {
      this.$refs.inspectionTable.getList();
      this.$refs.inspectionTable.getCounters();
      this.$refs.inProgressTable.getList();
    },

    resetFilter() {
      this.selectedOrganization = null;
      this.selectedDateRange = null;
      this.selectedInspectionForm = null;
      this.selectedCustomer = null;
      this.selectedLocations = null;
      this.selectedTags = [];
      this.selectedStatus = null;
      this.selectedAssignedTo = null;
      this.selectedCreator = null;
      this.selectedAssignedBy = null;
      this.selectedAssignedToGroup = null;
      this.selectedCanceledBy = null;
      this.selectedCompletedBy = null;
      this.selectedVehicleModelYear = null;
      this.selectedVehicleMake = null;
      this.selectedVehicleModel = null;
      this.selectedVehicleSeries = null;
      this.onlyTrashed = false;
      this.odometers = { from: null, to: null };
      this.odometer_unit = null;
      this.query = null;
      this.selectedRows = [];
      this.sort = "-updated_at";
      this.total = 0;
      this.pagination = {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      };
    },
  },

  watch: {},
};
</script>
