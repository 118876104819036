<template>
  <vehicle-ref-standardized
    :vehicle="estimate.vehicle"
    @vehicleChanged="$emit('vehicleChanged')"
    gallery-selector
  >
    <template #second-title>
      <h4>{{ title ?? estimate.code }}</h4>
    </template>

    <template #buttons>
      <slot name="buttons">
        <!--<button v-if="canCancel" @click="$emit('cancel')" class="delete">
        <span> {{ $t("COMMON.CANCEL") }} </span>
        <i class="far fa-trash-alt"></i>
      </button>-->
        <button v-if="canEdit" @click="$emit('edit')" class="edit">
          <span> {{ $t("COMMON.UPDATE") }} </span>
          <i class="far fa-edit"></i>
        </button>
        <button v-if="canView" @click="view" class="view">
          <span> {{ $t("ESTIMATES.SHOW_ESTIMATE") }} </span>
          <!-- <i class="fal fa-share"></i> -->
        </button>
      </slot>
    </template>
  </vehicle-ref-standardized>
</template>

<script>
import { QUERY_ACTIONS_EDIT } from "@/constants/common";
import VehicleRefStandardized from "./VehicleRefStandardized.vue";

export default {
  name: "estimate-ref-standardized",

  components: { VehicleRefStandardized },

  props: {
    estimate: {
      type: Object,
    },
    canCancel: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canView: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      QUERY_ACTIONS_EDIT,
    };
  },

  computed: {},

  methods: {
    view() {
      this.$emit("onView");
      if (this.$route.name != "List Estimates") {
        this.$router.push({
          name: "List Estimates",
          query: { id: this.estimate.id, action: QUERY_ACTIONS_EDIT },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
