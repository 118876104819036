var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.className},[(_vm.viewHeader)?_c('inspection-ref-standardized',{attrs:{"show-buttons":_vm.showButtons,"canCancel":_vm.canCancelInspection,"canEdit":_vm.canEditInspection,"canRedo":_vm.canRedoInspection,"canShare":_vm.canShareInspection,"inspection":_vm.inspection},on:{"cancel":function($event){_vm.showInspectionCancelModel = true},"edit":_vm.editInspection,"redo":function($event){_vm.showRedoInspectionConfirmationModel = true},"share":_vm.shareInspection,"vehicleChanged":function($event){return _vm.$emit('onViewInspection')}}}):_vm._e(),_c('div',{staticClass:"global-body"},[(_vm.showCode)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.CODE")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$objectDenomination(_vm.inspection))+" ")])]):_vm._e(),(_vm.inspection.work_order)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.WORK_ORDER")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.inspection.work_order)+" ")])]):_vm._e(),_c('dl',{staticClass:"width_1-2 status"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.STATUS")))]),_c('dd',[_c('inspection-status-badge',{attrs:{"inspection":_vm.inspection}})],1)]),(_vm.inspection.status == _vm.INSPECTION_STATUS_COMPLETED)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("INSPECTIONS.SCORE")))]),_c('dd',[_vm._v(_vm._s(_vm.inspection.score)+"%")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        _vm.$idExist(_vm.inspection.customer?.id) &&
        !!_vm.inspection.customer?.customer_type
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.CUSTOMER")))]),_c('dd',[_c('users',{attrs:{"users":[_vm.inspection.customer]}})],1)]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_REQUESTS) &&
        !!_vm.inspection.request
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.REQUEST")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.inspection.request}})],1)]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        _vm.$idExist(_vm.inspection.approver?.id) &&
        !!_vm.inspection.approver?.email
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.APPROVER")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.inspection.approver}})],1)]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_INSPECTION_FORMS) &&
        !!_vm.inspection.inspectionForm
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.INSPECTION_FORM")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.inspection.inspectionForm}})],1)]):_vm._e(),(!!_vm.inspection.created_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("INSPECTIONS.CREATED_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.inspection.created_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.inspection.creator
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("INSPECTIONS.CREATOR")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.inspection.creator}})],1)]):_vm._e(),(!!_vm.inspection.approved_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("INSPECTIONS.APPROVED_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.inspection.approved_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.inspection.approvedBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("INSPECTIONS.APPROVED_BY")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.inspection.approvedBy}})],1)]):_vm._e(),(!!_vm.inspection.assigned_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("INSPECTIONS.ASSIGNED_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.inspection.assigned_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.inspection.assignedBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("INSPECTIONS.ASSIGNED_BY")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.inspection.assignedBy}})],1)]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.inspection.assignedToUsers?.length
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("INSPECTIONS.ASSIGNED_TO")))]),_c('dd',_vm._l((_vm.inspection.assignedToUsers),function(user,idx){return _c('span',{key:idx},[_c('object-link',{attrs:{"object":user}}),_vm._v(" "+_vm._s(_vm.inspection.assignedToUsers.length - 1 > idx ? "," : " ")),_c('br')],1)}),0)]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ROLES) &&
        !!_vm.inspection.assignedToRoles?.length
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("INSPECTIONS.ASSIGNED_TO_GROUP")))]),_c('dd',_vm._l((_vm.inspection.assignedToRoles),function(role,idx){return _c('span',{key:idx},[_c('object-link',{attrs:{"object":role}}),_vm._v(" "+_vm._s(_vm.inspection.assignedToRoles.length - 1 > idx ? "," : " ")),_c('br')],1)}),0)]):_vm._e(),(!!_vm.inspection.started_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.STARTED_AT"))+" ")]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.inspection.started_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.inspection.startedBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.STARTED_BY"))+" ")]),_c('dd',[_c('object-link',{attrs:{"object":_vm.inspection.startedBy}})],1)]):_vm._e(),(!!_vm.inspection.completed_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.COMPLETED_AT"))+" ")]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.inspection.completed_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.inspection.completedBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.COMPLETED_BY"))+" ")]),_c('dd',[_c('object-link',{attrs:{"object":_vm.inspection.completedBy}})],1)]):_vm._e(),(_vm.inspection.canceled_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.CANCELED_AT"))+" ")]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.inspection.canceled_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.inspection.canceledBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.CANCELED_BY"))+" ")]),_c('dd',[_c('object-link',{attrs:{"object":_vm.inspection.canceledBy}})],1)]):_vm._e(),_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.LOCATIONS")))]),_c('dd',[_c('locations',{attrs:{"locations":_vm.inspection.allowedLocations}})],1)]),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
        !!_vm.inspection.organization
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.ORGANIZATION")))]),_c('dd',[_c('organization',{attrs:{"organization":_vm.inspection.organization}})],1)]):_vm._e(),(false)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t("COMMON.TAGS"))+" ")]),_c('dd',[_c('tags',{attrs:{"tags":_vm.inspection.tags},on:{"tagsUpdated":_vm.inspectionUpdated}})],1)]):_vm._e(),_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("INSPECTIONS.EXCERPT")))]),_c('dd',{domProps:{"innerHTML":_vm._s(_vm.inspection.excerpt)}})])]),_c('inspection-confirmation-modal',{attrs:{"confirm-action":_vm.cancelInspection,"confirm-button-text":_vm.$t('COMMON.YES_CANCEL'),"loading":_vm.loading,"message":_vm.$t('INSPECTIONS.CANCEL_THIS_INSPECTION'),"open":_vm.showInspectionCancelModel,"modal-classes":"modal-secondary cancel"},on:{"update:open":function($event){_vm.showInspectionCancelModel=$event}}}),_c('inspection-confirmation-modal',{attrs:{"confirm-action":_vm.redoInspection,"confirm-button-text":_vm.$t('COMMON.CONTINUE'),"cancelButtonText":_vm.$t('COMMON.CANCEL'),"loading":_vm.loading,"message":_vm.$t('INSPECTIONS.REDO_WARNING'),"open":_vm.showRedoInspectionConfirmationModel,"modal-classes":"modal-secondary cancel"},on:{"update:open":function($event){_vm.showRedoInspectionConfirmationModel=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }